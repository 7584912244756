<template>
  <div>
    <my-nav-bar
      :title="`验收记录(${lineName})`"
      left-text="返回"
      left-arrow
    />
  </div>
</template>

<script>
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'AcceptRecord',
  components: { myNavBar },
  data() {
    return {
      lineId: this.$route.query.line_id,
      lineName: this.$route.query.line_name
    }
  }
}
</script>

<style>

</style>
