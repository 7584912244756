var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('van-nav-bar', {
    staticClass: "nav-bar",
    attrs: {
      "title": _vm.title,
      "left-text": _vm.leftText,
      "left-arrow": _vm.leftArrow,
      "right-text": _vm.rightText,
      "fixed": ""
    },
    on: {
      "click-left": _vm.handleGoBack,
      "click-right": function clickRight($event) {
        return _vm.$emit('click-right');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }