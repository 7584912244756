<template>
  <van-nav-bar
    class="nav-bar"
    :title="title"
    :left-text="leftText"
    :left-arrow="leftArrow"
    :right-text="rightText"
    fixed
    @click-left="handleGoBack"
    @click-right="$emit('click-right')"
  />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      default: false
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleGoBack() {
      if (window.history.length <= 1) {
        location.href = '/'
      } else {
        this.$router.go(-1)
      }
      this.$emit('click-left-callback')
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-bar {
    z-index: 999;
  }
</style>
