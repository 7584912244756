var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "\u9A8C\u6536\u8BB0\u5F55(".concat(_vm.lineName, ")"),
      "left-text": "返回",
      "left-arrow": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }